<template>
  <v-row class="mx-0 mt-1">
    <v-col cols="12" class="py-0 px-0">
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.updated_at="{ item }">
          {{ item.updated_at }}
        </template>

        <template v-slot:item.rulename="{ item }">
          {{ item.rulename }}
        </template>

        <template v-slot:item.miner="{ item }">
          {{ item.miner }}
        </template>

        <template v-slot:item.fw="{ item }">
          {{ item.fw }}
        </template>

        <template v-slot:item.src="{ item }">
          {{ item.src }}
        </template>

        <template v-slot:item.src_short="{ item }">
          {{ item.src_short }}
        </template>

        <template v-slot:item.target="{ item }">
          {{ item.target }}
        </template>

        <template v-slot:item.target_short="{ item }">
          {{ item.target_short }}
        </template>

        <template v-slot:item.threat="{ item }">
          {{ item.threat }}
        </template>

        <template v-slot:item.description="{ item }">
          {{ item.description }}
        </template>

        <template v-slot:item.call_name="{ item }">
          <span class="form-link" @click="open(item)">
            {{ item.call_name }}
          </span>
        </template>

        <template v-slot:item.last_call_user="{ item }">
          {{ item.last_call_user }}
        </template>

        <template v-slot:item.id="{ item }">
          {{ item.id }}
        </template>

        <template v-slot:item.syslog_id="{ item }">
          {{ item.syslog_id }}
        </template>

        <template v-slot:item.api_id="{ item }">
          {{ item.api_id }}
        </template>

        <template v-slot:item.status="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="activeColor(item.status)"
                :class="activeIcon(item.status)"
                small
                v-bind="attrs"
                v-on="on"
              >
                fas fa-circle
              </v-icon>
            </template>
            <span>{{ statusInfo(item.status) }}</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <td
                      v-if="expandItem.value === 'description'"
                      width="150"
                      class="pl-3"
                    >
                      Description
                    </td>
                    <td v-if="expandItem.value === 'description'">
                      {{ item.description }}
                    </td>

                    <td
                      v-if="expandItem.value === 'call_name'"
                      width="150"
                      class="pl-3"
                    >
                      Event ID
                    </td>
                    <td v-if="expandItem.value === 'call_name'">
                      <span class="form-link" @click="open(item)">
                        {{ item.call_name }}
                      </span>
                    </td>

                    <td
                      v-if="expandItem.value === 'src'"
                      width="150"
                      class="pl-3"
                    >
                      Source
                    </td>
                    <td v-if="expandItem.value === 'src'">
                      {{ item.description }}
                    </td>

                    <td
                      v-if="expandItem.value === 'src_short'"
                      width="150"
                      class="pl-3"
                    >
                      Source short
                    </td>
                    <td v-if="expandItem.value === 'src_short'">
                      {{ item.src_short }}
                    </td>

                    <td
                      v-if="expandItem.value === 'target'"
                      width="150"
                      class="pl-3"
                    >
                      Target
                    </td>
                    <td v-if="expandItem.value === 'target'">
                      {{ item.target }}
                    </td>

                    <td
                      v-if="expandItem.value === 'target_short'"
                      width="150"
                      class="pl-3"
                    >
                      Target short
                    </td>
                    <td v-if="expandItem.value === 'target_short'">
                      {{ item.target_short }}
                    </td>

                    <td
                      v-if="expandItem.value === 'last_call_user'"
                      width="150"
                      class="pl-3"
                    >
                      Approved by
                    </td>
                    <td v-if="expandItem.value === 'last_call_user'">
                      {{ item.last_call_user }}
                    </td>

                    <td
                      v-if="expandItem.value === 'id'"
                      width="150"
                      class="pl-3"
                    >
                      Internal ID
                    </td>
                    <td v-if="expandItem.value === 'id'">
                      {{ item.id }}
                    </td>

                    <td
                      v-if="expandItem.value === 'syslog_id'"
                      width="150"
                      class="pl-3"
                    >
                      Syslog ID
                    </td>
                    <td v-if="expandItem.value === 'syslog_id'">
                      {{ item.syslog_id }}
                    </td>

                    <td
                      v-if="expandItem.value === 'api_id'"
                      width="150"
                      class="pl-3"
                    >
                      API ID
                    </td>
                    <td v-if="expandItem.value === 'api_id'">
                      {{ item.api_id }}
                    </td>

                    <td
                      v-if="expandItem.value === 'updated_at'"
                      width="150"
                      class="pl-3"
                    >
                      Date
                    </td>
                    <td v-if="expandItem.value === 'updated_at'">
                      {{ item.updated_at }}
                    </td>

                    <td
                      v-if="expandItem.value === 'rulename'"
                      width="150"
                      class="pl-3"
                    >
                      Rule:
                    </td>
                    <td v-if="expandItem.value === 'rulename'">
                      {{ item.rulename }}
                    </td>

                    <td
                      v-if="expandItem.value === 'miner'"
                      width="150"
                      class="pl-3"
                    >
                      Miner
                    </td>
                    <td v-if="expandItem.value === 'miner'">
                      {{ item.miner }}
                    </td>

                    <td
                      v-if="expandItem.value === 'fw'"
                      width="150"
                      class="pl-3"
                    >
                      Firewall
                    </td>
                    <td v-if="expandItem.value === 'fw'">
                      {{ item.fw }}
                    </td>

                    <td
                      v-if="expandItem.value === 'threat'"
                      width="150"
                      class="pl-3"
                    >
                      Threat
                    </td>
                    <td v-if="expandItem.value === 'threat'">
                      {{ item.threat }}
                    </td>

                    <td
                      v-if="expandItem.value === 'status'"
                      width="150"
                      class="pl-3"
                    >
                      Active
                    </td>
                    <td v-if="expandItem.value === 'status'">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            :color="activeColor(item.status)"
                            :class="activeIcon(item.status)"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            fas fa-circle
                          </v-icon>
                        </template>
                        <span>{{ statusInfo(item.status) }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],
      location: "",

      page: 1,
      pageTotalCount: 1,
      size: 1,

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== this.sizes.indexOf(this.tableSize)) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
  },
  methods: {
    open(item) {
      this.idAddForm = item.id;
      this.keywordAddForm = "securityPolicy";
    },
    activeColor(status) {
      if (status === 1) {
        //secondary
        return "#ccd0d3";
      } else if (status === 11) {
        //success
        return "#64c52a";
      } else if (status === 10 || status === 12) {
        //info
        return "#808485";
      } else if (status === 13 || status === 40) {
        //danger
        return "#ffd04e";
      } else if (status === 20) {
        //waring
        return "#e53355";
      } else {
        return "#e53355";
      }
    },
    activeIcon(status) {
      if (status === 1) {
        //secondary
        return "fas fa-circle";
      } else if (status === 10) {
        //info
        return "fas fa-plus-circle";
      } else if (status === 11) {
        //success
        return "fas fa-check-circle";
      } else if (status === 12) {
        //info
        return "fas fa-minus-circle";
      } else if (status === 13) {
        //danger
        return "fas fa-times-circle";
      } else if (status === 40) {
        //danger
        return "fas fa-exclamation-circle";
      } else if (status === 20) {
        //waring
        return "fas fa-exclamation-circle";
      } else {
        return "fas fa-circle";
      }
    },
    statusInfo(status) {
      if (status === 1) {
        //secondary
        return "Policy is not on Firewall";
      } else if (status === 10) {
        //success
        return "Part from policy is not on Firewall";
      } else if (status === 11) {
        //success
        return "Policy is active on Firewall";
      } else if (status === 12) {
        //info
        return "Part from policy is removed from Firewall";
      } else if (status === 13) {
        //danger
        return "Policy was removed from Firewall";
      } else if (status === 20) {
        //waring
        return "Not Completed on Synapsa but Policy exit on Firewall";
      } else if (status === 40) {
        //waring
        return "Revoke on Synapsa but Policy exit on Firewall";
      } else {
        return "-";
      }
    },
  },
};
</script>
